
import { defineComponent, onBeforeMount, ref } from 'vue';
import { Dialog, List } from 'vant';
import 'vant/es/dialog/style';
import { Http } from '@/sdk';
import QueryString from 'qs';
import router from '@/router';

export interface NoticeContent {
  content: string;
  publish_time: string;
}

export default defineComponent({
  components: {
    List
  },
  setup: function() {
    const sceneName = ref('');
    const sceneId = ref('');
    const sceneCmdCode = ref('');
    const historyNoticeDefault = [] as NoticeContent[];
    const historyNotice = ref(historyNoticeDefault);

    const loading = ref(false);
    const finished = ref(false);
    const offset = ref(0);

    const init = async () => {
      offset.value++;
      const result = await Http.get(`api/v1/admin/panel?offset=${(offset.value - 1) * 10 + 1}&limit=10`);
      historyNotice.value = [...historyNotice.value, ...(result?.data?.data?.bulletin_list || [])];
      sceneName.value = result?.data?.data?.scenic_name;
      sceneId.value = result?.data?.data?.scenic_id;
      sceneCmdCode.value = result?.data?.data?.passcode;

      if (result?.data?.data?.bulletin_list.length === 0) {
        finished.value = true;
      }

      loading.value = false;
    };

    onBeforeMount(async () => {
      init();
    });
    const noticeContent = ref('');

    const onLoad = async () => {
      await init();
    };

    const resetCmdClick = () => {
      Dialog.confirm({
        title: `确定重置${sceneName.value}入场口令吗？`,
        message: '',
        width: '320',
        confirmButtonText: '确定',
        confirmButtonColor: '#576B95'
      })
        .then(async () => {
          // on confirm
          const result = await Http.post('api/v1/admin/verification_code');
          if (result?.data?.data?.is_success) {
            sceneCmdCode.value = result?.data?.data?.passcode;
          }
        })
        .catch(() => {
          // on cancel
        });
    };

    const sendNoticeContent = () => {
      Dialog.confirm({
        title: '确定发送此条公告吗？',
        message: '',
        width: '320',
        confirmButtonText: '确定',
        confirmButtonColor: '#576B95'
      })
        .then(async () => {
          // on confirm
          await Http.post('api/v1/admin/bulletin', QueryString.stringify({ message: noticeContent.value }));
          noticeContent.value = '';
          init();
        })
        .catch(() => {
          // on cancel
        });
    };

    const onBack = () => {
      router.replace('/login/verification');
    };

    return {
      loading,
      finished,
      sceneId,
      sceneCmdCode,
      noticeContent,
      historyNotice,
      sceneName,
      resetCmdClick,
      sendNoticeContent,
      onBack,
      onLoad
    };
  }
});
